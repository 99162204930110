import React, { useContext, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout/Layout';
import Loading from '../components/loading/Loading';
import Seo from '../components/seo/Seo';
import Ending from '../components/ending/Ending';
import structureSeoData from '../utils/structureSeoData';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import { StatusContext } from '../contexts/StatusProvider';

const Results = ({ data, pageContext, location }) => {
  const [status] = useContext(StatusContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const challengeQuery = queryString.parse(location.search);
    if (challengeQuery.shared /*|| (status && status.question === 0)*/) {
      if (challengeQuery.challenge) {
        console.log(
          'Opening a challenge, taking you to the frontpage to setup challenge mode'
        );
        navigate(`/${location.search}`);
      } else {
        console.log(
          'Opening a share link, taking you to frontpage to start quiz'
        );
        navigate(`/`);
      }
    } else {
      setLoading(false);
    }
  }, [location]);

  const resultContent = data.sanityResult;
  const slug = pageContext.slug;
  const score = pageContext.score;
  const seoData = structureSeoData(pageContext.seo);

  const challenge = status ? status.challenge : null;

  return (
    <Layout>
      <Seo
        title={seoData.title}
        desc={seoData.description}
        image={seoData.image}
      />
      {!loading ? (
        <Ending
          title={resultContent.title}
          text={resultContent.text}
          score={score}
          slug={slug}
          challenge={challenge}
        />
      ) : (
        <Loading />
      )}
    </Layout>
  );
};

export const query = graphql`
  query EndingQuery($textId: String!) {
    sanityResult(id: { eq: $textId }) {
      title
      text {
        ...BlockContent
      }
    }
  }
`;

export default Results;
