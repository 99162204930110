const structureSeoData = (seoData) => {
  return {
    title: seoData && seoData.seoTitle ? seoData.seoTitle : null,
    description:
      seoData && seoData.seoDescription ? seoData.seoDescription : null,
    image: seoData && seoData.seoImage ? seoData.seoImage.asset.url : null,
  };
};

export default structureSeoData;
