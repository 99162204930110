import React, { useEffect, useState } from 'react';
import * as styles from './Ending.module.scss';
import { StaticQuery, graphql } from 'gatsby';
import PortableText from 'react-portable-text';
import { Heading, Button } from '@dnb/eufemia/components';
import Form from '../form/Form';
import FancyTitle from '../fancy-title/FancyTitle';
import Stamp from '../stamp/Stamp';
import { pageLoad } from '../../utils/track';
import { FacebookShareButton } from 'react-share';

const Ending = ({ title, text, score, slug, challenge }) => {
  return (
    <StaticQuery
      query={graphql`
        {
          sanityResultpage {
            title
            ctaText: ctaButtonText
            ctaLink: ctaButtonLink
            text {
              ...BlockContent
            }
          }
          sanityQuizpage {
            quizArray {
              _id
            }
          }
        }
      `}
      render={(data) => {
        return (
          <EndingSection
            ctaText={data.sanityResultpage.ctaText}
            ctaLink={data.sanityResultpage.ctaLink}
            questions={data.sanityQuizpage.quizArray.length}
            globalTitle={data.sanityResultpage.title}
            globalText={data.sanityResultpage.text}
            title={title}
            text={text}
            score={score}
            slug={slug}
            challenge={challenge}
          />
        );
      }}
    />
  );
};

const EndingSection = ({
  questions,
  globalTitle,
  title,
  globalText,
  text,
  score,
  slug,
  challenge,
  ctaLink,
  ctaText,
}) => {
  const [challengerName, setChallengerName] = useState('');
  const url = 'http://sjekklappen.dnb.no';

  useEffect(() => {
    // Tracking
    pageLoad();
  }, []);

  return (
    <div className={styles.main}>
      <Heading top="x-small" bottom="small" className={styles.headingTop}>
        {globalTitle}
      </Heading>
      <FancyTitle text={`${score}/${questions}`} />
      {text && (
        <div className={styles.resultText}>
          <PortableText content={text} />
        </div>
      )}
      {/* <Heading className={styles.heading}>Bli med i konkurransen!</Heading>
      <Form setChallengerName={setChallengerName} /> */}
      <div className={styles.challengeOuter}>
        {challenge && <ChallengeEnding challenge={challenge} score={score} />}
        <FacebookShareButton
          url={`${url}/${slug}?shared=true&challenge=true&challenger=${
            challengerName ? challengerName : 'din utfordrer'
          }&score=${score}`}
          quote={`Jeg fikk ${score} av ${questions} poeng, se om du kan slå meg`}
          hashtag={'#dnb-bilforsikring'}
        >
          <Button>Del utfordring</Button>
        </FacebookShareButton>
        {/*
        <FacebookShareButton
          url={`${url}/${slug}?shared=true`}
          hashtag={'#dnb-bilforsikring'}
        >
          <Button>Del resultat</Button>
        </FacebookShareButton>
        */}
      </div>
      {globalText && (
        <div className={styles.globalText}>
          <Stamp />
          <PortableText content={globalText} />
        </div>
      )}
      {ctaLink && ctaText && <Button href={ctaLink}>{ctaText}</Button>}
    </div>
  );
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const ChallengeEnding = ({ challenge, score }) => {
  if (!challenge.active) {
    return null;
  }
  const challengeScore = parseInt(challenge.score);
  if (score < challengeScore) {
    const upperCaseName = capitalizeFirstLetter(challenge.name);
    return (
      <div>
        {upperCaseName} slo deg med {challengeScore - score} riktige
      </div>
    );
  } else if (score === challengeScore) {
    return (
      <div>
        Både du og {challenge.name} fikk begge {score} riktige
      </div>
    );
  } else if (score > challengeScore) {
    return (
      <div>
        Du slo {challenge.name} med {score - challengeScore} riktige
      </div>
    );
  }
  return null;
};

export default Ending;
