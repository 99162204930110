import React from 'react';
import '@dnb/eufemia/style';

import * as styles from './Loading.module.scss';

const Loading = () => {
  return <div className={styles.loading}>Laster...</div>;
};

export default Loading;
